import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import Glide from '@glidejs/glide'
import '@glidejs/glide/dist/css/glide.core.min.css'
import { Box } from 'rebass/styled-components'

import IconArrowLeft from '../../img/arrow-left.inline.svg'
import IconArrowRight from '../../img/arrow-right.inline.svg'

const iconSize = '1rem'

const iconStyle = {
  width: iconSize,
  height: iconSize,
}

export const StyledArrowCSS = css`
  position: absolute;
  top: 12%;
  left: ${(props) => (props.direction === '<' ? '1rem' : 'auto')};
  right: ${(props) => (props.direction === '>' ? '1rem' : 'auto')};
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
  transform: translateY(-50%);
  color: ${(props) => props.theme.colors.primary};
  fill: currentColor;
  padding: 0.15rem;
  border-radius: 5rem;
  background-color: rgba(255, 255, 255, 0.7);
  transition: all 200ms ease-out;
  line-height: 0;

  &:hover {
    background-color: rgba(255, 255, 255, 1);
  }

  @media screen and (min-width: 768px) {
    display: block;
    padding: 0.65rem;
  }

  @media screen and (min-width: 1260px) {
    top: 40%;
    left: ${(props) => (props.direction === '<' ? '3.5rem' : 'auto')};
    right: ${(props) => (props.direction === '>' ? '3.5rem' : 'auto')};
  }
`

const StyledArrow = styled.button`
  ${StyledArrowCSS}
`

const Arrow = ({ direction, ...rest }) => {
  return (
    <StyledArrow className="glide__arrow" direction={direction} {...rest}>
      {direction === '<' && <IconArrowLeft style={iconStyle} />}
      {direction === '>' && <IconArrowRight style={iconStyle} />}
    </StyledArrow>
  )
}

const Carousel = ({ children, glideOptions }) => {
  useEffect(() => {
    new Glide('.glide', {
      ...glideOptions,
    }).mount()
  }, [glideOptions])

  return (
    <div className="glide">
      <div className="glide__track" data-glide-el="track">
        <ul className="glide__slides">
          {children &&
            children.map((child) => {
              if (child) {
                return (
                  <Box key={child.key} as="li" className="glide__slide">
                    {child}
                  </Box>
                )
              }
              return null
            })}
        </ul>
      </div>

      <div className="glide__arrows" data-glide-el="controls">
        <Arrow direction="<" data-glide-dir="<" />

        <Arrow direction=">" data-glide-dir=">" />
      </div>
    </div>
  )
}

Arrow.propTypes = {
  direction: PropTypes.string.isRequired,
}

Carousel.propTypes = {
  children: PropTypes.node.isRequired,
  glideOptions: PropTypes.object,
}

Carousel.defaultProps = {
  glideOptions: {},
}

export default Carousel
