export const getAdjacentPresentations = (presentationId, presentations) => {
  const currentIndex = presentations.edges.findIndex(
    ({ node }) => node.id === presentationId,
  )
  let nextIndex = currentIndex + 1
  let previousIndex = currentIndex + -1
  let next = null
  let previous = null

  if (presentations.edges[nextIndex]) {
    next = presentations.edges[nextIndex].node
  }

  if (presentations.edges[previousIndex]) {
    previous = presentations.edges[previousIndex].node
  }

  return {
    next,
    previous,
  }
}
