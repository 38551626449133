import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import slugify from 'slugify'
import { withTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Box, Flex, Link as RebassLink, Image } from 'rebass/styled-components'
import { rgba } from 'polished'

import SectionTitle from '../SectionTitle'
import VimeoPlayer from '../VimeoPlayer'
import AdjacentPresentation from './adjacentPresentation'
import { getFirstPresentationVersion } from '../../utils/getFirstPresentationVersion'

const PresentationContent = styled.div`
  color: ${(props) => props.theme.colors.primary};

  h2 {
    margin-top: 2rem;
    margin-bottom: 0.5rem;
    font-size: 1.5rem;
    font-weight: bold;
  }

  p,
  ol,
  ul {
    line-height: 1.5;
    font-size: 1.25rem;
  }
`

const StyledLink = styled(Link)`
  /* margin-right: 1rem; */
  padding: 0.5rem 1rem;
  text-decoration: none;
  white-space: nowrap;
  border-bottom: 4px solid
    ${(props) =>
    props.current
      ? props.theme.colors.primary
      : rgba(props.theme.colors.primary, 0.3)};
`

const AdjacentLinks = ({ children, sx, ...rest }) => (
  <Box
    sx={{
      width: '100%',
      position: 'absolute',
      top: '50%',
      right: 0,
      left: 0,
      transform: 'translateY(-50%)',
      zIndex: 1,
      ...sx,
    }}
    {...rest}
  >
    {children}
  </Box>
)

const PresentationDetail = ({
  id,
  title,
  videoURL,
  contentHTML,
  image,
  presentation,
  presentationLink,
  presentationSlug,
  presentationLanguage,
  versions,
  previousPresentation,
  nextPresentation,
  continueLinkLabel,
  workshopSetId = null,
  t,
}) => {
  const sortVersionsByPosition = (a, b) => {
    if (a.position > b.position) {
      return 1
    }
    if (a.position < b.position) {
      return -1
    }
    return 0
  }

  const getNextVersion = (currentId) => {
    const sortedVersions = versions.sort(sortVersionsByPosition)
    const index = sortedVersions.findIndex(
      (version) => version.id === currentId,
    )

    if (index < versions.length - 1) {
      const nextIndex = index + 1
      const version = versions.sort(sortVersionsByPosition)[nextIndex]
      return version
    }

    return false
  }

  const nextVersion = getNextVersion(id)
  let nextFirstPresentationVersionSlug = ''

  if (nextPresentation && nextPresentation.versions) {
    const nextFirstPresentationVersion = getFirstPresentationVersion(
      nextPresentation.versions,
    )

    if (typeof nextFirstPresentationVersion !== 'undefined') {
      nextFirstPresentationVersionSlug = nextFirstPresentationVersion.slug
    }
  }

  return (
    <>
      <Box
        sx={{
          width: '100%',
          minHeight: '4rem',
          maxHeight: '39rem',
          overflow: 'hidden',
          position: 'relative',
        }}
      >
        {image && image.local && (
          <Image
            as={Img}
            fluid={image.local.childImageSharp.fluid}
            alt={`${presentation.title} ${title}`}
          />
        )}

        <AdjacentLinks>
          {previousPresentation && (
            <AdjacentPresentation
              type="previous"
              language={presentationLanguage}
              presentation={previousPresentation}
              workshopSetId={workshopSetId}
            />
          )}

          {nextPresentation && (
            <AdjacentPresentation
              type="next"
              language={presentationLanguage}
              presentation={nextPresentation}
              workshopSetId={workshopSetId}
            />
          )}
        </AdjacentLinks>
      </Box>

      <Flex
        sx={{
          py: 4,
          flexDirection: ['column', 'column', 'row'],
          alignItems: ['center', 'center', 'flex-start'],
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            px: [3, 3, 0],
            width: ['auto', 'auto', '50rem'],
          }}
        >
          <Box>
            <SectionTitle sx={{ mb: 4, textAlign: 'left' }} as="h1">
              {presentation.title}
            </SectionTitle>

            {versions && versions.length > 1 && (
              <Box sx={{ width: '100vw', overflow: 'scroll' }}>
                <Flex mb={4}>
                  {versions.sort(sortVersionsByPosition).map((version) => {
                    const versionSlug = slugify(version.title, { lower: true })

                    return (
                      <StyledLink
                        key={version.id}
                        current={version.id === id}
                        to={`/${
                          presentationLanguage || 'en'
                        }/presentation/${presentationSlug}/${versionSlug}`}
                        state={{ workshopSetId }}
                      >
                        {version.title}
                      </StyledLink>
                    )
                  })}
                </Flex>
              </Box>
            )}
          </Box>

          {videoURL && (
            <Box mb={4}>
              <VimeoPlayer url={videoURL} />
            </Box>
          )}

          <Box
            sx={{
              mb: 4,
            }}
          >
            <PresentationContent
              dangerouslySetInnerHTML={{
                __html: contentHTML,
              }}
            />
          </Box>

          {nextVersion && (
            <Box sx={{ mb: 4, color: 'primary', fontSize: 3 }}>
              <Link
                to={`/${presentationLanguage}/presentation/${presentationSlug}/${slugify(
                  nextVersion.title,
                  { lower: true },
                )}`}
              >
                {continueLinkLabel ||
                  t('presentations:complete-presentation', {
                    defaultValue: 'Click here to read complete presentation',
                  })}
              </Link>
            </Box>
          )}

          <Box
            sx={{
              display: 'grid',
              gridAutoFlow: ['column', 'column'],
              gridAutoColumns: 'max-content',
              gap: 3,
              alignItems: 'flex-start',
              mb: 4,
            }}
          >
            {!nextVersion && nextPresentation && (
              <RebassLink
                variant="buttonLink"
                as={Link}
                to={`/${presentationLanguage}/presentation/${nextPresentation.slug}/${nextFirstPresentationVersionSlug}`}
              >
                {t('presentations:next-presentation', {
                  defaultValue: 'Next',
                })}
              </RebassLink>
            )}

            {presentationLink && (
              <RebassLink
                variant="buttonLink"
                href={presentationLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('presentations:buy-now', {
                  defaultValue: 'Buy now',
                })}
              </RebassLink>
            )}
          </Box>
        </Box>
      </Flex>
    </>
  )
}

AdjacentLinks.propTypes = {
  children: PropTypes.node.isRequired,
  sx: PropTypes.object,
}

PresentationDetail.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  contentHTML: PropTypes.string.isRequired,
  videoURL: PropTypes.string,
  image: PropTypes.object,
  presentation: PropTypes.object.isRequired,
  presentationLink: PropTypes.string,
  presentationLanguage: PropTypes.string.isRequired,
  presentationSlug: PropTypes.string.isRequired,
  versions: PropTypes.array,
  previousPresentation: PropTypes.object,
  nextPresentation: PropTypes.object,
  continueLinkLabel: PropTypes.string,
  workshopSetId: PropTypes.string,
  t: PropTypes.func.isRequired,
}

PresentationDetail.defaultProps = {
  videoURL: null,
  continueLinkLabel: null,
  workshopSetId: null,
}

export default withTranslation()(PresentationDetail)
