import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Translation } from 'react-i18next'
import { withTheme } from 'styled-components'

import isBrowser from '../utils/isBrowser'

const Paywall = ({ onSuccess, theme }) => {
  const [scriptLoaded, setScriptLoaded] = useState(false)
  const libName = 'TwoCoInlineCart'
  const config = {
    app: {
      merchant: '250355816075',
      iframeLoad: 'checkout',
    },
    cart: { host: 'https://secure.2checkout.com', customization: 'inline' },
  }

  useEffect(() => {
    if (!scriptLoaded && isBrowser) load2CheckoutScript()
  })

  const load2CheckoutScript = () => {
    const script = document.createElement('script')
    script.async = true
    script.src =
      'https://secure.avangate.com/checkout/client/twoCoInlineCart.js'
    document.body.appendChild(script)
    script.onload = () => {
      for (const namespace in config) {
        if (Object.prototype.hasOwnProperty.call(config, namespace)) {
          window[libName].setup.setConfig(namespace, config[namespace])
        }
      }

      window[libName].register()

      window[libName].events.subscribe('payment:finalized', function () {
        console.log('Payment was finalized.')
        onSuccess(true)
      })

      window[libName].events.subscribe('fulfillment:finalized', function () {
        console.log('Fulfillment was finalized.')
      })

      setScriptLoaded(true)
    }
  }

  return (
    <Translation>
      {(t) => (
        <a
          href="#buy"
          className="avangate_button"
          product-code="1E3EC92CC1"
          product-quantity="1"
          as="a"
          style={{
            padding: '0.5rem 2rem',
            backgroundColor: 'white',
            border: '2px solid',
            borderColor: theme.colors.primary,
            borderRadius: '3rem',
            color: theme.colors.primary,
            fontSize: '1.5rem',
            fontWeight: 'bold',
            lineHeight: '1.75',
            verticalAlign: 'middle',
            textDecoration: 'none',
            '&:visited': {
              color: theme.colors.primary,
            },
          }}
        >
          {t('purchase:linkLabel', { defaultValue: 'Purchase!' })}
        </a>
      )}
    </Translation>
  )
}

Paywall.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired,
}

export default withTheme(Paywall)
