import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import styled from 'styled-components'
import slugify from 'slugify'
import { withTranslation } from 'react-i18next'

import IconArrowLeft from '../../img/arrow-left.inline.svg'
import IconArrowRight from '../../img/arrow-right.inline.svg'
import { getFirstPresentationVersion } from '../../utils/getFirstPresentationVersion'
import { StyledArrowCSS } from '../Carousel'

const iconSize = '1rem'

const iconStyle = {
  width: iconSize,
  height: iconSize,
}

const StyledArrow = styled(Link)`
  ${StyledArrowCSS}
`

const AdjacentPresentation = ({
  language,
  presentation,
  type,
  workshopSetId = null,
}) => {
  const { title, slug, versions } = presentation
  const presentationSlug = slug || slugify(title, { lower: true })
  const direction = type === 'previous' ? '<' : '>'
  const firstVersion = getFirstPresentationVersion(versions)

  if (!firstVersion) {
    return null
  }

  const firstVersionSlug = firstVersion.slug

  return (
    <StyledArrow
      to={`/${language}/presentation/${presentationSlug}/${firstVersionSlug}`}
      direction={direction}
      title={presentation.title}
      state={{ workshopSetId }}
    >
      {direction === '<' && <IconArrowLeft style={iconStyle} />}
      {direction === '>' && <IconArrowRight style={iconStyle} />}
    </StyledArrow>
  )
}

AdjacentPresentation.propTypes = {
  language: PropTypes.string.isRequired,
  presentation: PropTypes.shape({
    title: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    versions: PropTypes.arrayOf(
      PropTypes.shape({
        slug: PropTypes.string.isRequired,
      }),
    ),
  }),
  type: PropTypes.oneOf(['next', 'previous']).isRequired,
  workshopSetId: PropTypes.string,
}

AdjacentPresentation.defaultProps = {
  workshopSetId: null,
}

export default withTranslation()(AdjacentPresentation)
