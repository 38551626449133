import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { withI18next, Link } from 'gatsby-plugin-i18n'
import { Box, Flex, Heading, Text } from 'rebass/styled-components'

import Layout from '../components/Layout'
import Section from '../components/Section'
import Container from '../components/Container'
import LoginForm from '../components/LoginForm'
import Paywall from '../components/Paywall'
import PresentationDetail from '../components/PresentationDetail'
import isBrowser from '../utils/isBrowser'
import { draftjsContentToHTML } from '../utils/draftjsContentToHTML'
import { getAdjacentPresentations } from '../utils/getAdjacentPresentations'
import { getAdjacentPresentationsInSet } from '../utils/getAdjacentPresentationsInSet'

const PresentationVersionTemplate = ({ data, pageContext, location }) => {
  const {
    presentationVersion,
    otherPresentations,
    translations,
    sitePlugin,
  } = data
  const { apikey, apiURL } = sitePlugin.pluginOptions
  const { language, presentationId } = pageContext
  const { workshopSetId = null } =
    location && location.state ? location.state : {}
  let {
    next: nextPresentation,
    previous: previousPresentation,
  } = getAdjacentPresentations(presentationId, otherPresentations)

  if (workshopSetId) {
    console.log('Getting adjacent presentations from workshop set!')
    const workshopSet = data.workshopSets.edges.find(({ node: set }) => set.id === workshopSetId)
    const adjacentPresentationInSet = getAdjacentPresentationsInSet(presentationId, workshopSet)

    nextPresentation = adjacentPresentationInSet.next
    previousPresentation = adjacentPresentationInSet.previous
  }

  const {
    id,
    title,
    videoURL,
    content,
    isPublic,
    presentation,
    continueLinkLabel,
  } = presentationVersion
  const presentationImage = presentation && presentation.image
  const presentationLink = presentation && presentation.link
  const contentHTML = draftjsContentToHTML(content)
  const [logged, setLogged] = useState(
    isBrowser ? localStorage.getItem('isLoggedIn') : false,
  )

  const handleSuccess = (loginResult) => {
    if (loginResult) {
      isBrowser && localStorage.setItem('isLoggedIn', loginResult)
      setLogged(loginResult)
    }
  }

  return (
    <Layout language={language} translations={translations} translationsRoute="presentation">
      <main>
        <Section
          variant={!isPublic && !logged ? 'section' : 'sectionNoPadding'}
        >
          <Container maxWidth="50rem">
            {!isPublic && !logged && (
              <Flex
                flexDirection="row"
                alignItems="flex-start"
                justifyContent="space-between"
              >
                <Box sx={{ width: '45%' }}>
                  <Heading as="h2" mb={3}>
                    Not a member?
                  </Heading>

                  <Text sx={{ fontSize: '1.25rem' }}>
                    <Link to={'/sign-up'}>Sign up</Link> and get all access to
                    the videos and presentations for free
                  </Text>

                  <Text my={3}>or</Text>

                  <Paywall onSuccess={handleSuccess} />
                </Box>

                <Box sx={{ width: '45%' }}>
                  <Heading as="h2" mb={3}>
                    Already member?
                  </Heading>

                  <LoginForm
                    apikey={apikey}
                    apiURL={apiURL}
                    onSuccess={handleSuccess}
                  />
                </Box>
              </Flex>
            )}
          </Container>

          {(isPublic || logged) && (
            <>
              <PresentationDetail
                id={id}
                title={title}
                videoURL={videoURL}
                contentHTML={contentHTML}
                image={presentationImage}
                presentation={presentation}
                presentationLink={presentationLink}
                presentationSlug={presentation.slug}
                presentationLanguage={presentation.language}
                versions={presentation.versions}
                previousPresentation={previousPresentation}
                nextPresentation={nextPresentation}
                workshopSetId={workshopSetId}
                continueLinkLabel={continueLinkLabel}
              />
            </>
          )}
        </Section>
      </main>
    </Layout>
  )
}

PresentationVersionTemplate.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.object,
  location: PropTypes.object,
}

PresentationVersionTemplate.defaultTypes = {
  data: {},
  pageContext: {},
  location: {},
}

export default withI18next()(PresentationVersionTemplate)

export const pageQuery = graphql`
  query PresentationVersionTemplate(
    $id: String!
    $language: String!
    $translatedIds: [String]
  ) {
    locales: allLocale(
      filter: {
        language: { eq: $language }
        ns: { in: ["messages", "presentations"] }
      }
    ) {
      ...LocalLocaleFragment
    }
    sitePlugin(name: { eq: "gatsby-theme-sarvasya" }) {
      pluginOptions {
        apiURL
        apikey
      }
    }
    workshopSets: allWorkshopSets {
      edges {
        node {
          id
          presentations {
            id
            title
            slug
            versions {
              id
              title
              slug
              position
            }
          }
        }
      }
    }
    otherPresentations: allPresentations(
      filter: { language: { eq: $language } }
    ) {
      edges {
        node {
          id
          title
          slug
          versions {
            id
            slug
            position
          }
        }
      }
    }
    translations: allPresentations(filter: { _id: { in: $translatedIds } }) {
      edges {
        node {
          id
          title
          slug
          language
        }
      }
    }
    presentationVersion: presentationVersions(id: { eq: $id }) {
      id
      videoURL
      title
      content
      isPublic
      continueLinkLabel
      presentation {
        id
        title
        language
        slug
        link
        image {
          local {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 90) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
        versions {
          id
          title
          position
        }
      }
    }
  }
`
