export const getAdjacentPresentationsInSet = (presentationId, workshopSet) => {
  if (!workshopSet) {
    return {}
  }

  const set = Object.prototype.hasOwnProperty.call(workshopSet, 'node')
    ? workshopSet.node
    : workshopSet
  const currentIndex = set.presentations.findIndex(
    (presentation) => presentation.id === presentationId,
  )
  let nextIndex = currentIndex + 1
  let previousIndex = currentIndex + -1
  let next = null
  let previous = null

  if (set.presentations[nextIndex]) {
    next = set.presentations[nextIndex]
  }

  if (set.presentations[previousIndex]) {
    previous = set.presentations[previousIndex]
  }

  return {
    next,
    previous,
  }
}
