import React, { useState, useRef } from 'react'
import { findDOMNode } from 'react-dom'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Flex, Box } from 'rebass/styled-components'
import { lighten } from 'polished'
import Vimeo from 'react-player'
import screenfull from 'screenfull'

import Play from '../img/play.inline.svg'
import Pause from '../img/pause.inline.svg'
import Duration from './Duration'

const progressBarHeight = 6

const PlayerWrapper = styled.div`
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
 
  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
`

const Controls = styled(Flex)``

const PlayButton = styled.button`
  width: 3rem;
  height: 3rem;
  display: flex;
  margin-right: 1rem;
  justify-content: center;
  align-items: center;
  -webkit-appearance: none;
  appearance: none;
  background-color: ${props => props.theme.colors.primary};
  border: none;
  border-radius: 4rem;
  outline: none;

  svg {
    width: 1.5rem;
    height: 1.5rem;
    fill: #fff;
  }
`

const ProgressBar = styled.div`
  position: relative;
  width: 100%;
  height: ${progressBarHeight}px;
  border-radius: ${progressBarHeight/2}px;
  background-color: ${props => lighten(0.7, props.theme.colors.primary)};
`

const Progress = styled.div`
  position: absolute;
  width: ${props => props.width || 0};
  height: ${progressBarHeight}px;
  border-radius: ${progressBarHeight/2}px;
  background-color:
    ${props => props.loaded ?
    lighten(0.6, props.theme.colors.primary) : props.theme.colors.primary};
  transitioN: width 50ms ease-in-out;
`

const VimeoPlayer = ({ url }) => {
  const [playing, setPlaying] = useState(false)
  const [seeking] = useState(false)
  const [progress, setProgress] = useState(0)
  const [loadedProgress, setLoadedProgress] = useState(0)
  const [played, setPlayed] = useState(0)
  const [duration, setDuration] = useState(0)
  // const [fullscreen, setFullscreen] = useState(false)
  const playerRef = useRef(null)

  const handlePlayPause = () => {
    setPlaying(!playing)
  }

  const handlePause = () => {
    setPlaying(false)
  }

  const handleStart = () => {
    setPlaying(true)
  }

  const handleProgress = state => {
    // We only want to update time slider if we are not currently seeking
    if (!seeking) {
      setProgress(state.played)
      setLoadedProgress(state.loaded)
      setPlayed(state.played)
    }
  }

  const handleDuration = (duration) => {
    setDuration(duration)
  }

  const handleClickFullscreen = () => {
    // eslint-disable-next-line react/no-find-dom-node
    screenfull.request(findDOMNode(playerRef.current))
  }

  return (
    <>
      <PlayerWrapper onClick={handlePlayPause}>
        <Vimeo
          className="react-player"
          url={url}
          width="100%"
          height="100%"
          ref={playerRef}
          playing={playing}
          light
          onPause={handlePause}
          onStart={handleStart}
          onProgress={handleProgress}
          onDuration={handleDuration}
        />
      </PlayerWrapper>

      <Controls alignItems="center" p={3}>
        <PlayButton onClick={handlePlayPause}>
          {playing ? <Pause /> : <Play />}
        </PlayButton>

        <Box mr={3} minWidth="2rem">
          {played ? <Duration seconds={duration * played} /> : '0:00'}
        </Box>

        <Box flexGrow={1}>
          <ProgressBar>
            <Progress loaded width={`${loadedProgress * 100}%`} />
            <Progress width={`${progress * 100}%`} />
          </ProgressBar>
        </Box>

        <Box ml={3} minWidth="2rem">
          {duration ? <Duration seconds={duration} /> : '0:00'}
        </Box>

        <Box>
          <button type="button" onClick={handleClickFullscreen}>
            Fullscreen
          </button>
        </Box>
      </Controls>
    </>
  )
}

VimeoPlayer.propTypes = {
  url: PropTypes.string,
}

export default VimeoPlayer
